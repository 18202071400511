import cn from 'classnames';
import styles from './styles.module.scss';
import { FC } from 'react';
import { ROUTES } from '../../common';
import { Button } from '../../components';
import { NavLink } from 'react-router-dom';

const preHeadItems = [
  'Затверджено',
  'Наказом Директора ТОВ',
  '"ТЕРРА-ЛЕКС  ГАРАНТ" ',
  '62410, Харківська обл.,',
  'Харківський р-н,',
  'селище Глибоке,',
  'вул. Кірова, буд. 18',
  'ЄДРПОУ 38526176',
  'Директор Браташ Олександр Віталійович',
  '№ 01-07/24 від 01.07.2024 р.',
];

const PersonalData: FC<{}> = () => {
  const URL_SITE = 'https://terraleksgarant.com.ua';

  return (
    <section className={styles['PersonalData']}>
      <div className={styles['pad-bot-s']}>Введено в дію з 01.07.2024 р.</div>
      {preHeadItems.map((text, i) => (
        <div key={i} className={cn(styles['text-align-right'], styles['pad-bot-s'])}>
          {text}
        </div>
      ))}

      <h1 className={styles['title']}>ЗГОДА НА ОБРОБКУ ПЕРСОНАЛЬНИХ ДАНИХ</h1>
      <h2 className={styles['subtitle']}>(для сайту {URL_SITE})</h2>

      <ol>
        <li>
          Я, Клієнт (Заявник), як суб’єкт персональних даних, підписуючи Згоду на обробку
          персональних даних, додаючи документи та іншу інформацію щодо себе на веб - сайті
          ТОВАРИСТВА З ОБМЕЖЕНОЮ ВІДПОВІДАЛЬНІСТЮ "ТЕРРА-ЛЕКС ГАРАНТ" (далі – Товариство) - та
          проставляючи відповідну відмітку - натискаючи клавішу, (я прочитав та ознайомлений, що
          виражає надання цієї згоди на Веб - сайті та підтверджуючі ЄЦП ДІЯ {URL_SITE}), надаю ТОВ,
          "ТЕРРА-ЛЕКС ГАРАНТ" код юридичної особи 38526176 свою однозначну, беззастережну і
          необмежену згоду на:
        </li>
        <ol>
          <li>
            Обробку персональних даних, у тому числі, на їх збір, систематизацію, накопичення,
            зберігання, уточнення (оновлення та зміну), використання, розповсюдження, передачу,
            знеособлення, блокування та знищення будь-якої інформації, що стосується мене, як
            Клієнта, зокрема, але не виключно, інформації щодо: статі, прізвища, імені, по -
            батькові, паспортних даних, реєстраційного номеру облікової картки платника податків,
            яка зазначається при укладенні договору та/або використовується під час виконання
            договору, дати, місця та року народження, фотографії документу, що посвідчує особу -
            громадянства, адреси фактичного проживання та реєстрації, сімейного, соціального,
            майнового становища, освіти, професії, адреси електронної пошти, в т.ч. інформації, що
            містить комерційну таємницю (далі – «Персональні дані»).
          </li>
          <li>Метою обробки Товариством Персональних даних Клієнта є:</li>
          <ol>
            <li>
              Aналіз наданих Клієнтом даних для прийняття Товариством рішення щодо укладення
              Договорів надання в оренду та попередня перевірка претендентів на працевлаштування.
            </li>
            <li>
              Здійснення Товариством своєї фінансово - господарської діяльності, виконання умов
              укладених договорів;
            </li>
            <li>Пропонування повного переліку послуг Товариства.</li>
            <li>
              Інформування Клієнта про діючі умови програми лояльності, бонуси, промокоди, акції,
              спеціальні пропозиції.
            </li>
            <li>Інша мета, що не суперечить Закону України «Про захист персональних даних».</li>
          </ol>

          <li>
            Відповідно до частини 2 статті 2 Закону України «Про запобігання та протидію легалізації
            (відмиванню) доходів, одержаних злочинним шляхом, фінансуванню тероризму та фінансуванню
            розповсюдження зброї масового знищення» на Товариство також покладаються обов’язки щодо
            обробки персональних даних для цілей запобігання та протидії легалізації (відмиванню)
            доходів, одержаних злочинним шляхом, фінансуванню тероризму та фінансуванню
            розповсюдження зброї масового знищення.
          </li>
          <li>На виконання поставленої мети, я, як Клієнт надаю свою згоду на:</li>
          <ol>
            <li>
              Передачу, поширення (у тому числі транскордонне) та надання своїх даних органам
              державної влади та їх структурним підрозділам, банкам та усім бюро кредитних історій,
              які створені та здійснюють свою діяльність відповідно до законодавства України (далі –
              Бюро кредитних історій), та іншим підприємствам, установам та організаціям; з якими
              підписаний договори про надання інформаційних послуг, а саме: ТОВ "Відкриті дані"
              ЄДРПОУ 38526176, "ДП НАІС" ЄДРПОУ ТОВ «Українське бюро кредитних історій» ЄДРПОУ
              33546706. Здійснення запитів з використанням моїх Персональних даних до Бюро кредитних
              історій, сервісів дистанційної верифікації та інших сервісів, що у порядку,
              передбаченому законодавством України, надають інформацію про мене та отримання моїх
              додаткових даних з наступною обробкою таких даних.
            </li>
            <li>
              Те, що Оператори та інші підприємства, установи та організації (спільно, чи кожен
              окремо) виступатимуть володільцями та/або розпорядниками Персональних даних.
            </li>
          </ol>
          <li>
            Здійснення дій зазначених у пунктах 1.1. - 1.4. Згоди не потребує додаткового
            інформування Клієнта.
          </li>
        </ol>
        <li>
          Клієнт підтверджує, що він має законне право на надання усіх без виключення даних,
          зазначених ним при Реєстрації на веб - сайті Товариства та переданих Товариству.
        </li>
        <li>
          Клієнт погоджується на використання Товариством усіх даних, переданих безпосередньо
          Товариству та законно отриманих Товариством у порядку передбаченому п. 1.4. з метою обміну
          кореспонденцією та інформацією. Така інформація може передаватися поштовим зв’язком,
          електронною поштою, телефонним зв’язком, спеціальними засобами програмного забезпечення,
          які пов'язані з наданими Клієнтом телефонними номерами (Viber, WhatsApp та ін.), засобами
          зв'язку у соціальних мережах (Facebook та ін.) та іншими електронними засобами зв’язку,
          також при участі в розіграші акцій та інше та розміщення у разі виграшу на сайті
          товариства.
        </li>
        <li>Ця згода на обробку персональних даних надається без обмеження строку.</li>
        <li>
          Клієнт, підписуючи згоду обробку персональних даних на веб - сайті Товариства,
          підтверджує, що він:
          <ol>
            <li>Повідомлений про володіння персональних даних.</li>
            <li>Про склад та зміст зібраних Товариством персональних даних Клієнта.</li>
            <li>Про включення Персональних даних до бази персональних даних Товариства.</li>
            <li>Про свої права, передбачені Законом України «Про захист персональних даних».</li>
            <li>Про мету збору даних та третіх осіб, яким передаються Персональні дані.</li>
            <li>
              Засвідчує, що склад та зміст Персональних даних в повній мірі є відповідними,
              адекватними та ненадмірними стосовно визначеної цією Згодою мети їх обробки.
            </li>
          </ol>
        </li>
        <li>
          Товариство забезпечує належний захист Персональних даних Клієнта у спосіб, визначений
          чинним законодавством України. Захист Персональних даних передбачає заходи, спрямовані на
          запобігання випадковій втраті, знищенню, незаконній обробці, у тому числі незаконному
          доступу чи знищенню Персональних даних.
        </li>
        <li>
          Товариство веде облік операцій, пов’язаних з обробкою Персональних даних Клієнта та
          доступом до них відповідно до вимог законодавства.
        </li>
        <li>
          Клієнт повідомлений про передбачену статтею 182 Кримінального кодексу України
          відповідальність за незаконне збирання, зберігання, використання, поширення ним
          конфіденційної інформації.
        </li>
        <li>
          Стаття 182 Кримінального кодексу України. Порушення недоторканності приватного життя:
          <ol>
            <li>
              Незаконне збирання, зберігання, використання, знищення, поширення конфіденційної
              інформації про особу або незаконна зміна такої інформації, крім випадків, передбачених
              іншими статтями цього Кодексу - караються штрафом від п'ятисот до однієї тисячі
              неоподатковуваних мінімумів доходів громадян або виправними роботами на строк до двох
              років, або арештом на строк до шести місяців, або обмеженням волі на строк до трьох
              років.
            </li>
            <li>
              Ті самі дії, вчинені повторно, або якщо вони заподіяли істотну шкоду охоронюваним
              законом правам, свободам та інтересам особи - караються арештом на строк від трьох до
              шести місяців або обмеженням волі на строк від трьох до п'яти років, або позбавленням
              волі на той самий строк.
            </li>
          </ol>
        </li>
      </ol>

      <div className={styles['btn-wrapper']}>
        <NavLink to={ROUTES.AUTH} className="link">
          <Button onClick={() => {}}>ПРОЧИТАВ ТА ОЗНАЙОМЛЕНИЙ</Button>
        </NavLink>
      </div>
    </section>
  );
};

export { PersonalData };
