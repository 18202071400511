import styles from './styles.module.scss';
import { FC } from 'react';
import { CircleNumber } from '../../components';

const instructionStepItems = [
  'Ознайомтесь зі згодою про обробку персональних данних',
  'Натисніть на кнопку прочитав та ознайомлений',
  'Пройдіть аутентифікацію через сервіс ДІЯ підпис',
  'Очікуйте оповіщення від роботодавця або орендаря!',
];

const Instruction: FC<{}> = () => {
  return (
    <section className={styles['Instruction']}>
      <div className={styles['content-wrapper']}>
        <h1 className={styles['title']}>Інструкція</h1>
        <ul className={styles['list']}>
          {instructionStepItems.map((text, i) => (
            <li key={i} className={styles['list-item']}>
              <CircleNumber number={i + 1} />
              {text}
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
};

export { Instruction };
