import { FC } from 'react';
import { ROUTES } from '../common';
import { Navigate, Routes, Route } from 'react-router-dom';
import { Auth, About, Instruction, PersonalData } from '../views';

const Router: FC<{}> = () => {
  return (
    <>
      <Routes>
        <Route path={ROUTES.AUTH} element={<Auth />} />
        <Route path={ROUTES.ABOUT} element={<About />} />
        <Route path={ROUTES.INSTRUCTION} element={<Instruction />} />
        <Route path={ROUTES.PERSONAL_DATA_AGREEMENT} element={<PersonalData />} />

        <Route path="*" element={<Navigate to={ROUTES.AUTH} replace />} />
      </Routes>
    </>
  );
};

export { Router };
