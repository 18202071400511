import styles from './styles.module.scss';
import { FC } from 'react';
import { ReactSVG } from 'react-svg';

const Auth: FC<{}> = () => {
  return (
    <section className={styles['Auth']}>
      <div className={styles['content-wrapper']}>
        <h1 className={styles['title']}>Аутифікація через сервіс ДІЯ підпис</h1>
        <h2 className={styles['subtitle']}>Вiдкрийте застосунок та вiдскануйте QR</h2>
        <ReactSVG
          className={styles['qr-img']}
          src="/images/icons/qr-code.svg"
          desc="QR code"
          title="QR code"
        />
      </div>
      <ReactSVG className={styles['img']} src="/images/icons/scan-qr.svg" title="Scan qr code" />
    </section>
  );
};

export { Auth };
