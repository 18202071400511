import styles from './styles.module.scss';
import { FC } from 'react';
import { CircleNumber } from '../../components';

const items = [
  'Переверіямо претендентів, щодо факторів, які необхідні для продажу та придбання нерухомості',
  'Оцінюємо мінімальні ризики, безпеку та інше у цій сфері',
  'Можемо представити рекомендації для вас',
];

const About: FC<{}> = () => {
  return (
    <section className={styles['About']}>
      <div className={styles['content-wrapper']}>
        <h1 className={styles['title']}>Про нас</h1>
        <h3 className={styles['subtitle']}>Ми ТОВ «ТЕРРА-ЛЕКС ГАРАНТ»</h3>
        <p className={styles['description']}>
          На ринку нерухомості та послуг - вже <b>більше 10 років</b> і зарекомендували себе, як
          стабільна компанія, що{' '}
          <b>допомагає людям у сфері діяльності пошуку, оренди та придбання житла</b>.
        </p>
        <ul className={styles['list']}>
          {items.map((text, i) => (
            <li key={i} className={styles['list-item']}>
              <CircleNumber number={i + 1} />
              {text}
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
};

export { About };
