import styles from './styles.module.scss';
import { FC, ReactNode } from 'react';
import { Header, Footer } from './components';

const Layout: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <section className={styles['Layout']}>
      <Header />
      <div className={styles['wrapper']}>
        <div className="container">
          <main>{children}</main>
        </div>
      </div>
      <Footer />
    </section>
  );
};

export { Layout };
