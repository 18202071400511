const ROUTES = {
  PERSONAL_DATA_AGREEMENT: '/personal-data-agreement',
  INSTRUCTION: '/instruction',
  ABOUT: '/about',
  AUTH: '/auth',
};

const NAV_ITEMS = [
  {
    title: 'Аутифікація',
    to: ROUTES.AUTH,
  },
  {
    title: 'Про нас',
    to: ROUTES.ABOUT,
  },
  {
    title: 'Інструкція',
    to: ROUTES.INSTRUCTION,
  },
  {
    title: 'Згода на обробку персональних данних',
    to: ROUTES.PERSONAL_DATA_AGREEMENT,
  },
];

export { NAV_ITEMS, ROUTES };
