import styles from './styles.module.scss';
import { FC } from 'react';

interface CircleNumberInterface {
  number: number;
}

const CircleNumber: FC<CircleNumberInterface> = ({ number }) => {
  return (
    <span className={styles['CircleNumber']}>
      <span className={styles['num']}>{number}</span>
    </span>
  );
};

export { CircleNumber };
