import styles from './styles.module.scss';
import { FC, ReactNode } from 'react';

interface ButtonInterface {
  children: ReactNode;
  onClick: () => void;
}

const Button: FC<ButtonInterface> = ({ children, onClick }) => {
  return (
    <button className={styles['Button']} onClick={onClick}>
      {children}
    </button>
  );
};

export { Button };
