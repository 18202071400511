import cn from 'classnames';
import styles from './styles.module.scss';
import { FC, useState } from 'react';
import { ReactSVG } from 'react-svg';
import { NavLink } from 'react-router-dom';
import { ROUTES, NAV_ITEMS } from '../../../common';

const Header: FC<{}> = () => {
  const [menuIsActive, setMenuIsActive] = useState(false);

  const menuBtnClickHandler = (): void => {
    setMenuIsActive(!menuIsActive);
  };

  return (
    <header className={styles['Header']}>
      <div className={cn(styles['content'], 'container')}>
        <div className={styles['logo-wrapper']}>
          <NavLink to={ROUTES.AUTH} className="link">
            <ReactSVG
              className={styles['logo']}
              src="/images/icons/logo.svg"
              desc="ТОВ “ТЕРРА-ЛЕКС ГАРАНТ”"
              title="Logo"
            />
          </NavLink>
          <div className={styles['logo-text-wrapper']}>
            <div className={styles['logo-title']}>ТОВ “ТЕРРА-ЛЕКС ГАРАНТ”</div>
            <div className={styles['logo-subtitle']}>Гарант надійності та порядності</div>
          </div>
        </div>
        <nav className={styles['nav']}>
          <div
            className={cn(styles['menu-toggle'], {
              [styles['menu-toggle--active']]: menuIsActive,
            })}
            onClick={menuBtnClickHandler}
          >
            <span className={styles['bar']} />
            <span className={styles['bar']} />
            <span className={styles['bar']} />
          </div>
          <ul
            className={cn(styles['nav-list'], {
              [styles['nav-list--is-active']]: menuIsActive,
            })}
          >
            {NAV_ITEMS.map(({ title, to }, i) => (
              <li key={i} className={styles['nav-list-item']}>
                <NavLink to={to} className={styles['link']} onClick={menuBtnClickHandler}>
                  {title}
                </NavLink>
              </li>
            ))}
          </ul>
        </nav>
      </div>
    </header>
  );
};

export { Header };
