import cn from 'classnames';
import styles from './styles.module.scss';
import { FC } from 'react';
import { ReactSVG } from 'react-svg';
import { NavLink } from 'react-router-dom';
import { NAV_ITEMS } from '../../../common';

const Footer: FC<{}> = () => {
  const getCopyrightYear = (): string | number => {
    const currentYear = new Date().getFullYear();
    return String(currentYear) === '2024' ? currentYear : `2024-${currentYear}`;
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <footer className={styles['Footer']}>
      <div>
        <div className={cn(styles['content-wrapper'], 'container')}>
          <ReactSVG
            className={styles['logo']}
            src="/images/icons/logo.svg"
            desc="ТОВ “ТЕРРА-ЛЕКС ГАРАНТ”"
            onClick={scrollToTop}
            title="Logo"
          />
          <ul className={styles['nav-list']}>
            {NAV_ITEMS.map(({ title, to }, i) => (
              <li key={i} className={styles['nav-list-item']}>
                <NavLink to={to} className={styles['link']}>
                  {title}
                </NavLink>
              </li>
            ))}
          </ul>
        </div>
        <div className={styles['copyright']}>
          © Copyright {getCopyrightYear()} All Rights Reserved
        </div>
      </div>
    </footer>
  );
};

export { Footer };
